import { loginByOAuth2, loginByTencentQrcode } from '@/apis'
import { reactive } from 'vue'
import { useQueryParams, useUser } from '.'
import router from '@/router'
import store from '@/store'
import { M_TOKEN } from '@/store/types'
import { ElMessage } from 'element-plus'

export const useWxLogin = () => {
  const { setUser } = useUser()
  const queryState = reactive({
    authCode: '',
    state: ''
  })
  const { auth_code, code, state, type } = useQueryParams()
  queryState.authCode = code || auth_code
  let isAuthLogin = type ? true : false
  queryState.state = state
  console.log('queryState: ', queryState)
  const doLogin = async fn => {
    // 如果没有code则通过账号密码登录
    if (!queryState.authCode) {
      router.replace('/login')
      return false
    }
    try {
      let result = {}
      if (isAuthLogin) {
        console.log('isAuthLogin: ', isAuthLogin)
        // 走内部鉴权模式
        result = await loginByOAuth2(queryState.state, queryState.authCode)
      } else {
        result = await loginByTencentQrcode(queryState.state, queryState.authCode)
      }
      if (result.isOk) {
        setUser(result.data)
        store.commit(M_TOKEN, result.data.sessionId)
        //内建版本
        localStorage.setItem('cropPattern', result.data.cropPattern)
        typeof fn === 'function' && fn()
      }
    } catch (error) {
      ElMessage.error(error.message)
    }
  }
  return { queryState, doLogin }
}
